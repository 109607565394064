import React, { useEffect, useRef, useState } from "react";
import styles from "./dropdown.module.scss";

interface DropdownProps {
  dropdownBtn: React.ReactNode;
  dropdownList: React.ReactNode;
  dropdownDirection: "left" | "right";
}

const Dropdown = ({
  dropdownBtn,
  dropdownList,
  dropdownDirection,
}: DropdownProps) => {
  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const ref = useRef<HTMLDivElement>(null);

  const handleHideDropdown = (event: KeyboardEvent) => {
    if (event.key === "Escape") {
      setShowDropdown(false);
    }
  };

  const handleClickOutside = (event: Event) => {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      setShowDropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleHideDropdown, true);
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("keydown", handleHideDropdown, true);
      document.removeEventListener("click", handleClickOutside, true);
    };
  });

  return (
    <div className={styles.dropdown} ref={ref} data-testid="dropdown">
      <div
        className={styles.dropdownBtn}
        onClick={() => setShowDropdown(!showDropdown)}
        data-testid="dropdownBtn"
      >
        {dropdownBtn}
      </div>

      <ul
        className={`${styles.dropdownContent} ${showDropdown && styles.open} ${
          styles[dropdownDirection]
        }`}
        data-testid="dropdownList"
      >
        {dropdownList}
      </ul>
    </div>
  );
};
export default Dropdown;
