import Container from "@components/Container";
import Dropdown from "@components/Dropdown";
import { useAuth } from "@lib/authContext";
import { useUser } from "@lib/hooks/useUser";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import React, { Fragment } from "react";
import { FaBars, FaChevronDown, FaUserAlt } from "react-icons/fa";
import styles from "./header.module.scss";

const menuItens = [
  {
    label: "Benefícios",
    href: "/dashboard",
  },
  {
    label: "Apólice",
    href: "/dashboard/policies",
  },
  {
    label: "Pagamentos",
    href: "/dashboard/payments",
  },
  {
    label: "Acionamentos",
    href: "/dashboard/claim",
  },
];

interface HeaderProps {
  flat?: boolean;
  fixed?: boolean;
}

export default function Header({ flat = false, fixed = false }: HeaderProps) {
  const auth = useAuth();
  const router = useRouter();
  const user = useUser();
  const firstName = user.data?.full_name.split(" ")[0];

  return (
    <header
      className={`
        ${styles.headerContainer} 
        ${flat ? styles.flat : ""}
        ${fixed ? styles.fixed : ""}
      `}
      data-testid="header"
    >
      <Container>
        <nav className={styles.linksMobile}>
          <Dropdown
            dropdownBtn={<FaBars size="10px" />}
            dropdownList={menuItens.map((item) => (
              <li key={item.label}>
                <Link href={item.href}>
                  <a>{item.label}</a>
                </Link>
              </li>
            ))}
            dropdownDirection="left"
          />
        </nav>

        <div className={styles.logo} data-testid="logo">
          <Link href="/dashboard">
            <a>
              <Image
                src="/icon-cloud.svg"
                alt="Segurize Icon"
                width="47px"
                height="32px"
              />
            </a>
          </Link>
        </div>

        <nav className={styles.linksDesktop}>
          {menuItens.map((item) => (
            <Link href={item.href} key={item.label}>
              <a className={item.href === router.pathname ? styles.active : ""}>
                {item.label}
              </a>
            </Link>
          ))}
        </nav>

        <Dropdown
          dropdownBtn={
            <div className={styles.userMenuBtn}>
              <FaUserAlt />
              <span>{firstName}</span>
              <FaChevronDown />
            </div>
          }
          dropdownList={
            <Fragment>
              <li>
                <Link href="/dashboard/profile">
                  <a>Sua conta</a>
                </Link>
              </li>
              <li>
                <a onClick={auth.logout}>Sair</a>
              </li>
            </Fragment>
          }
          dropdownDirection="right"
        ></Dropdown>
      </Container>
    </header>
  );
}
